import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {



      fetchVendor(ctx,queryParams) {
      return new Promise((resolve, reject) => {
axios.get(`/api/v1/get-recipt-records-need-approve`,{ params: queryParams })
          .then(response =>resolve(response))
          .catch(error => reject(error))
      })

    },
    sendResponse(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approve:approvestate.approvestate,
          notes:approvestate.notes
        }

        let url=`/api/v1/approve-recipt-record/${approvestate.id}`
        axios
          .post(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },

  },
};
